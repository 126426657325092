@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* DARK MODE */
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);
    
    /* Light MODE */
    --color-bg-lmode: #ffffff;
    --color-bg-variant-lmode: #eeeeee;
    --color-primary-lmode: #f4bc3a;
    --color-primary-variant-lmode: #cb9f39;
    --color-white-lmode: #000;
    --color-light-lmode: rgba(183, 183, 183, 0.6);


    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

#light {
    background: var(--color-bg-lmode);
    color: var(--color-white-lmode);
}
#dark {
    background: var(--color-bg);
    color: var(--color-white);
}

html {
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    display: none;
}
body {
    font-family: "Poppins", sans-serif;
    line-height: 1.7;   
}
/* =========== Genral Styles =========== */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5 {
    font-weight: 500;
}
h1 {
    font-size: 2.5rem;
}
footer {
    z-index: 9;
}
section{
    margin: 2rem 0;
    padding: 2rem 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#dark section#header {
    margin-bottom: 8rem;
}
#light section#header {
    margin-bottom: 8rem;
}
header {
    min-height: 100vh;
}


#light section > h2 ,#light section > h5 {
    text-align: center;
    color: var(--color-white-lmode);
}
#dark section > h2 ,#dark section > h5 {
    text-align: center;
    color: var(--color-white);
}
#light section > h2 {
    color: var(--color-primary-lmode);
    margin-bottom: 3rem;
}
#dark section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}
#light .text-light {
    color: var(--color-light-lmode);
}
#dark .text-light {
    color: var(--color-light);
}
#light a {
    color: var(--color-primary-lmode);
    transition: var(--transition);
}
#dark a {
    color: var(--color-primary);
    transition: var(--transition);
}
#light a:hover {
    color: var(--color-white-lmode);
}
#dark a:hover {
    color: var(--color-white);
}
#light .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary-lmode);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary-lmode);
    transition: var(--transition);
}
#light .btn:hover {
    background: var(--color-white-lmode);
    color: var(--color-bg-lmode);
    border-color: transparent;
}
#light .btn-primary {
    background: var(--color-primary-lmode);
    color: var(--color-bg-lmode);
}
#dark .btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}
#dark .btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}
#dark .btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}
img {
    display: block;
    width: 100%;
    object-fit: cover;
}

#dark .themeModeSwitcher {
    width: 130px;
    height: fit-content;
    margin: auto ;
}
#dark .themeModeSwitcher > .react-switch-bg {
    width: 100% !important;
    position: relative;
}
#light .themeModeSwitcher {
    width: 130px;
    height: fit-content;
    margin: auto ;
}
#light .themeModeSwitcher > .react-switch-bg {
    width: 100% !important;
    position: relative;
}
#dark .themeModeSwitcher > .react-switch-bg:after {
    content: 'DARK MODE';
    font-size: 65%;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translate(27%, -40%);
}
#light .themeModeSwitcher > .react-switch-bg:after {
    content: 'LIGHT MODE';
    font-size: 65%;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translate(27%, -40%);
    color: white;
}
#dark .react-switch-handle {
    transform: translateX(105px) !important;
}


/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width:1024px) {
    #dark .container {
        width: var(--container-width-md);
    }
    #dark section {
        margin-top: 6rem;
    }
    /* LIGHT */
    #light .container {
        width: var(--container-width-md);
    }
    #light section {
        margin-top: 6rem;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width:600px) {
    #dark .container {
        width: var(--container-width-sm);
    }
    #dark section > h2 {
        margin-bottom: 2rem;
    }
    /* LIGHT */
    #light .container {
        width: var(--container-width-sm);
    }
    #light section > h2 {
        margin-bottom: 2rem;
    }

    #dark .themeModeSwitcher {
        position: fixed !important;
        bottom: 55px !important;
        left: 50%;
        transform: translate(-50%, 0);
    }
    #light .themeModeSwitcher {
        position: fixed !important;
        bottom: 55px !important;
        left: 50%;
        transform: translate(-50%, 0);
    }
    
}
