/* ================== About ===================== */
.about__container {
    display: grid;
    grid-template-columns: 25% 60%;
    gap: 15%;
}
#dark .about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary));
    display: grid;
    place-items: center;
}
#dark .about__me img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}
#dark .about__me img:hover {
    transform: rotate(0);
}
#light .about__me img:hover {
    transform: rotate(0);
}
.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}
#dark .about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}
#dark .about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
#dark .about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
.about__card h5 {
    font-size: 0.95rem;
}
#dark .about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

/* LIGHT */

#light .about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary-lmode));
    display: grid;
    place-items: center;
}
#light .about__me img {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

#light .about__card {
    background: var(--color-bg-variant-lmode);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}
#light .about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant-lmode);
    cursor: default;
}
#light .about__icon {
    color: var(--color-primary-lmode);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}
#light .about__card small {
    font-size: 0.7rem;
    color: var(--color-white-lmode);
}

#dark .about__text {
    height: 500px;
    margin-top: 20px;
    transition: all 0.3s ease;
}
#dark .about__text p {
    transition: all 0.3s ease;
}
#dark .about__text.partlyShown {
    height: 150px;
    transition: all 0.3s ease;
}
#dark .about__text.partlyShown p {
    height: 100px !important;
    overflow: hidden;
    transition: all 0.3s ease;
}
#dark .partlyShown .showMore {
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 0 25px 25px var(--color-bg);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-bg-variant);
    transition: all 0.3s ease;
}
#dark .about__text .showLess {
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 0 0 0 transparent;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-bg-variant);
    transition: all 0.3s ease;
}

/* LIGHT */
#light .about__text {
    height: 500px;
    margin-top: 20px;
    transition: all 0.3s ease;
}
#light .about__text p {
    transition: all 0.3s ease;
}
#light .about__text.partlyShown {
    height: 150px;
    transition: all 0.3s ease;
}
#light .about__text.partlyShown p {
    height: 100px !important;
    overflow: hidden;
    transition: all 0.3s ease;
}
#light .partlyShown .showMore {
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 0 25px 25px var(--color-bg-lmode);
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-bg-variant);
    transition: all 0.3s ease;
}
#light .about__text .showLess {
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 0 0 0 transparent;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--color-bg-variant);
    transition: all 0.3s ease;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    #dark .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    #dark .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    /* LIGHT */
    #light .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }
    #light .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    #dark .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }
    #dark .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    /* LIGHT */
    #light .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }
    #light .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .about__text {
        margin-bottom: 170%;
    }
    .about__text.partlyShown {
        margin-bottom: 0%;
    }
}
/* ================== End Of About ===================== */
