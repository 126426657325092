/* ================== Services ===================== */
#dark .services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}
#dark .service {
    background: var(--color-bg-variant);
    border-radius: 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}
#dark .service:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
#dark .service__head {
    background: var(--color-primary);
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}
#dark .service__head h3 {
    color: var(--color-bg);
    font-size: 1rem;
    text-align: center;
}
#dark .service__list {
    padding: 2rem;
}
#dark .service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
#dark .service__list-icon {
    color: var(--color-primary);
    margin-top: 2px;
}
#dark .service__list p {
    font-size: 0.9rem;
}

/* LIGHT */

#light .services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}
#light .service {
    background: var(--color-bg-variant-lmode);
    border-radius: 2rem;
    border: 1px solid var(--color-primary-lmode);
    height: fit-content;
    transition: var(--transition);
}
#light .service:hover {
    background: transparent;
    border-color: var(--color-primary-variant-lmode);
    cursor: default;
}
#light .service__head {
    background: var(--color-primary-lmode);
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}
#light .service__head h3 {
    color: var(--color-bg-lmode);
    font-size: 1rem;
    text-align: center;
}
#light .service__list {
    padding: 2rem;
}
#light .service__list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
#light .service__list-icon {
    color: var(--color-primary-lmode);
    margin-top: 2px;
}
#light .service__list p {
    font-size: 0.9rem;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width:1024px) {
    #dark .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    #dark .service {
        height: auto;
    }
    #light .services__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    #light .service {
        height: auto;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width:600px) {
    #dark .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    #light section#services {
        margin-top: 35rem;
    }
    #light .services__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}

/* ================== End Of Services ===================== */
