/* ================== Portfolio ===================== */

.container.portfolio__container {
    width: 1000px;
}
.container.portfolio__container img {
    height: 200px;
}
.coverLayer h3,
.coverLayer .portfolio__item-cta,
.coverLayer p {
    opacity: 1;
    transition: var(--transition);
}
.coverLayer:hover h3,
.coverLayer:hover .portfolio__item-cta,
.coverLayer:hover p {
    opacity: 1;
}

#dark .coverLayer h3 {
    color: white;
    font-size: 2rem;
}
#light .coverLayer h3 {
    color: white;
    font-size: 2rem;
}
.coverLayer {
    height: 500px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 15px;
    transition: var(--transition);
    opacity: 0;
}
.coverLayerContent {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: space-around;
    justify-content: space-around;
    text-align: center;
}
.coverLayer:hover {
    background: #000000cf;
    z-index: 1;
    opacity: 1;
}
#dark .swiper-button-prev,
#dark .swiper-button-next {
    color: #ffffff;
    background: var(--color-primary);
    border-radius: 50px;
    padding: 30px;
    box-shadow: 0 0 20px black;
}
#light .swiper-button-prev,
#light .swiper-button-next {
    color: #000000 !important;
    background: var(--color-primary-lmode);
    border-radius: 50px;
    padding: 30px;
    box-shadow: 0 0 20px black;
}
.portfolio__item-cta {
    display: flex;
    justify-content: space-around;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
/* @media screen and (max-width:1024px) {
    #dark section .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    #light section .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
} */
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    #dark .swiper-button-prev,
    #dark .swiper-button-next {
        color: #ffffff;
        background: transparent;
        border-radius: 50px;
        padding: 0px;
        box-shadow: none;
    }
    #light .swiper-button-prev,
    #light .swiper-button-next {
        color: #ffffff !important;
        background: transparent;
        border-radius: 50px;
        padding: 0px;
        box-shadow: none;
    }
    #dark .swiper-button-prev {
        margin-top: -10px;
    }
    #dark .swiper-button-next {
        margin-top: -9px;
    }
    #light .swiper-button-prev {
        margin-top: -10px;
    }
    #light .swiper-button-next {
        margin-top: -9px;
    }
    .portfolio__item-cta {
        display: flex;
        justify-content: center;
    }
    #light .coverLayer h3,
    #light .coverLayer .portfolio__item-cta,
    #light .coverLayer p {
        opacity: 1;
        transition: var(--transition);
        color: white;
    }
    .coverLayer {
        opacity: 1;
        background: #000000cf;
    }
}

/* ================== End Of Portfolio ===================== */
