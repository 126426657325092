/* ================== Header ===================== */
header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container {
    /* background-image: url(https://i.vimeocdn.com/video/579679891-225df720fa964391a5a6515d8c524dc85edcf2919dba667a9cd63b22151c6206-d?mw=1300&mh=732);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vw; */
    text-align: center;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/* ====== CTA ====== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}
/* ======== HEADER SOCIALS ======== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: fixed;
    left: 50px;
    bottom: 3rem;
}
#dark .header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}
#light .header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary-lmode);
}
/* ====== ME ====== */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 22rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
}
/* ====== SCROLL DOWN ====== */

#dark a.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}
#light a.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
    color: var(--color-white-lmode);
}
#light .container.header__container h5 {
    color: var(--color-white-lmode);
}
.react-typewriter-text {
    text-align: center !important;
}
/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    .header {
        height: 68vh;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    .header {
        height: 100vh;
    }
    .header__socials,
    .scroll__down {
        display: none;
    }
    .header__container {
        justify-content: flex-start;
    }
    .react-typewriter-text {
        text-align: center !important;
        font-size: 1.8rem;
    }
}
/* ================== End Of Header ===================== */
