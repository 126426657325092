/* ================== skills ===================== */
#dark .skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
#dark .skills__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
#dark .skills__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}
#dark .skills__container div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}
#dark .skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
#dark .skills__details {
    display: flex;
    gap: 1rem;
}
#dark .skills__details svg {
    margin-top: 6px;
    color: var(--color-primary);
}

/* LIGHT */

#light .skills__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
#light .skills__container > div {
    background: var(--color-bg-variant-lmode);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
#light .skills__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant-lmode);
}
#light .skills__container div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary-lmode);
}
#light .skills__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}
#light .skills__details {
    display: flex;
    gap: 1rem;
}
#light .skills__details svg {
    margin-top: 6px;
    color: var(--color-primary-lmode);
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width: 1024px) {
    #dark .skills__container {
        grid-template-columns: 1fr;
    }
    #dark .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    #dark .skills__content {
        padding: 1rem;
    }
    #light .skills__container {
        grid-template-columns: 1fr;
    }
    #light .skills__container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    #light .skills__content {
        padding: 1rem;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width: 600px) {
    #dark section#skills {
        margin-top: 12rem;
    }
    #dark .skills__container {
        gap: 1rem;
    }
    #dark .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
    #light section#skills {
        margin-top: 12rem;
    }
    #light .skills__container {
        gap: 1rem;
    }
    #light .skills__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}

/* ================== End Of skills ===================== */
