/* ================== Contact ===================== */

.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}
.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
#dark .contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
#dark .contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}
.contact__option svg {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
/* Form */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
#dark input, #dark textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    color: var(--color-white);
}

/* LIGHT */

#light .contact__option {
    background: var(--color-bg-variant-lmode);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}
#light .contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant-lmode);
}
#light input, #light textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant-lmode);
    color: var(--color-white-lmode);
}



/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */
@media screen and (max-width:1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap:2rem ;
    }
}
/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
@media screen and (max-width:600px) {
    .container.contact__container {
        width: var(--container-width-sm);
    }
}

/* ================== End Of Contact ===================== */
